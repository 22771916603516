import axios from "axios";
import { handleAxiosError, handleOtherStatusCodes } from "./errors";
import { apiUrl } from "src/configs/url";
import { headers } from "./config";

export class ErrorServices {
  async Create({ message }) {
    try {
      const res = axios.post(
        apiUrl + "/logs",
        {
          error: message,
        },
        {
          headers,
        }
      );

      if (res.status === 200) {
        return res.data;
      } else {
        handleOtherStatusCodes(res.status);
        return;
      }
    } catch (error) {
      handleAxiosError(error);
      return;
    }
  }
}
