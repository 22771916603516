import { AppContextProvider } from "./context/AppContextProvider";
import AppBonBonKahveRouter from "./routers";

export default function AppBonBonKahve() {
  return (
    <AppContextProvider>
      <div className="h-screen w-full relative">
        <AppBonBonKahveRouter />
      </div>
    </AppContextProvider>
  );
}
