import axios from "axios";
import { apiUrl } from "src/configs/url";
import { cookies, headers, headersAuth } from "./config";
import { handleAxiosError, handleOtherStatusCodes } from "./errors";

export class AuthServices {
  async SignIn({ email, password }) {
    try {
      const res = await axios.post(`${apiUrl}/users/sign_in`, {
        api_user: {
          email: email,
          password: password,
        },
      });
      if (res.status === 200) {
        return res.data;
      } else {
        handleOtherStatusCodes(res.status);
        return false;
      }
    } catch (err) {
      handleAxiosError(err);
      return false;
    }
  }

  async refreshToken() {
    try {
      const res = await axios.post(`${apiUrl}/users/refresh`, {
        token: cookies.token,
      });

      if (res.status === 200) {
        return res.data;
      } else {
        handleOtherStatusCodes(res.status);
        return false;
      }
    } catch (err) {
      handleAxiosError(err);
      return false;
    }
  }
}
