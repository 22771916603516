import { apiUrl } from "src/configs/url";
import { handleAxiosError, handleOtherStatusCodes } from "./errors";
import axios from "axios";
import { headers, headersAuth, headersFormData } from "./config";

export class MembersServices {
  async registration({
    name,
    phone,
    password,
    email,
    photo,
    national_id_number,
  }) {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("phone", phone);
    formData.append("password", password);
    formData.append("email", email);
    formData.append("photo", photo);
    formData.append("national_id_number", national_id_number);

    try {
      const res = await axios.post(`${apiUrl}/dashboard/members`, formData, {
        headers: headersFormData,
      });
      if (res.status === 200) {
        return res.data;
      } else {
        handleOtherStatusCodes(res.status);
        return false;
      }
    } catch (err) {
      handleAxiosError(err);
      return false;
    }
  }

  async get({ id }) {
    try {
      const res = await axios.get(`${apiUrl}/dashboard/members/${id}`, {
        headers: headersAuth,
      });

      if (res.status === 200) {
        return res.data;
      } else {
        handleOtherStatusCodes(res.status);
        return false;
      }
    } catch (err) {
      handleAxiosError(err);
      return false;
    }
  }

  async gets({ limit = 20, offset = 0 }) {
    try {
      const res = await axios.get(
        `${apiUrl}/dashboard/members?limit=${limit}&offset=${offset}`,
        { headers: headersAuth }
      );
      if (res.status === 200) {
        return res.data;
      } else {
        handleOtherStatusCodes(res.status);
        return false;
      }
    } catch (err) {
      // if (err.response.status === 401) {
      //   document.cookie = "token=; Max-Age=0";
      //   window.location.href = "/";
      // }

      handleAxiosError(err);
      return false;
    }
  }

  async delete({ id }) {
    try {
      const res = await axios.delete(`${apiUrl}/dashboard/members/${id}`, {
        headers: headersAuth,
      });
      if (res.status === 200) {
        return res.data;
      } else {
        handleOtherStatusCodes(res.status);
        return false;
      }
    } catch (err) {
      handleAxiosError(err);
      return false;
    }
  }

  async addMemberCard({ cardNumber, id }) {
    try {
      const res = await axios.post(
        `${apiUrl}/dashboard/members/${id}/cards`,
        {
          card_number: cardNumber,
        },
        {
          headers: headersAuth,
        }
      );
      if (res.status === 200) {
        return res.data;
      } else {
        handleOtherStatusCodes(res.status);
        return false;
      }
    } catch (err) {
      handleAxiosError(err);
      return false;
    }
  }

  async getMemberCard({ id }) {
    try {
      const res = await axios.get(`${apiUrl}/dashboard/cards/${id}`, {
        headers: headersAuth,
      });
      if (res.status === 200) {
        return res.data;
      } else {
        handleOtherStatusCodes(res.status);
        return false;
      }
    } catch (err) {
      handleAxiosError(err);
      return false;
    }
  }
}
