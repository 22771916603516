import axios from "axios";
import { apiUrl } from "src/configs/url";
import { headers, headersAuth, headersFormData } from "./config";
import { handleAxiosError, handleOtherStatusCodes } from "./errors";

export class ProductServices {
  async update({ name, id }) {
    const formData = new FormData();
    formData.append("name", name);

    try {
      const res = await axios.patch(
        `${apiUrl}/dashboard/products/${id}`,
        formData,
        {
          headers: headersFormData,
        }
      );
      if (res.status === 200) {
        return res.data;
      } else {
        handleOtherStatusCodes(res.status);
        return false;
      }
    } catch (err) {
      if (err.response.status === 401) {
        document.cookie = "token=; Max-Age=0";
        window.location.href = "/";
      }
      handleAxiosError(err);
      return false;
    }
  }

  async editStock({ stock, id }) {
    const formData = new FormData();
    formData.append("stock", stock);

    try {
      const res = await axios.patch(
        `${apiUrl}/dashboard/products/${id}`,
        formData,
        {
          headers: headersFormData,
        }
      );
      if (res.status === 200) {
        return res.data;
      } else {
        handleOtherStatusCodes(res.status);
        return false;
      }
    } catch (err) {
      handleAxiosError(err);
      return false;
    }
  }

  async get({ id }) {
    try {
      const res = await axios.get(`${apiUrl}/dashboard/products/${id}`, {
        headers,
      });

      if (res.status === 200) {
        return res.data;
      } else {
        handleOtherStatusCodes(res.status);
        return false;
      }
    } catch (err) {
      handleAxiosError(err);
      return false;
    }
  }
}
